import { Model, type ModelConstructor } from "@moirei/dobby";
import { get, isString, pick } from "lodash";
import { useToast } from "vue-toastification";

type Options = {
  itemId?: string;
  itemName?: string;
  message?: string;
};

export const useDeleteModel = <T extends Model>(
  model: ModelConstructor<T>,
  options?: Options
) => {
  const deleting = ref(false);
  let primaryKeys: string[] = options?.itemId ? [options.itemId] : [];
  const itemName = options?.itemName || "name";
  const modelName = caseToSentence(model.entity);

  if (!primaryKeys.length) {
    // primaryKeys = model.primaryKeys || [model.primaryKey]
    primaryKeys = [model.primaryKey];
  }

  const { wrapConfirm } = useConfirm();
  const toast = useToast();

  const getItemWhere = (item: T | object | string) =>
    isString(item)
      ? {
          [primaryKeys[0]]: item,
        }
      : pick(item, primaryKeys);

  const delForce = async (item: T | object | string) => {
    toast.warning(`Deleting ${modelName} ${get(item, itemName)}`);

    const p =
      item instanceof Model ? item.$delete() : model.delete(getItemWhere(item));

    return p
      .then(() => {
        toast.success(`Deleted ${modelName} ${get(item, itemName)}`);
      })
      .catch((error: Error) => {
        toast.error(`Error deleting ${modelName} ${get(item, itemName)}`);
        throw error;
      });
  };

  const del = wrapConfirm(delForce, (item) => ({
    title: `Delete ${get(item, itemName)}`,
    message:
      options?.message ||
      `Are you sure? All ${modelName} data will be deleted.`,
  }));

  return {
    deleting,
    del,
    delForce,
  };
};
